import React from "react";
import { Fragment } from "react";
import { Link } from "react-router-dom";

export default function Oferta(props) {
  return (
    <Fragment>
      <div className="section-oferta">
        <div className="container-std">
          <h1 id="testelement" className="section-title">
            {props.oferta.titlu}
          </h1>
          <div className="flex-oferta">
            <div className="container-oferta">
              <div className="text-oferta">
                <p className="paragraph-standard">{props.oferta.text[0]}</p>
              </div>
              <div className="oferta-buttons">
                <Link
                  className="button-oferta w-button"
                  to={
                    props.lang === "RO"
                      ? `/oferte/${props.oferta.offerId}`
                      : `/en/offers/${props.oferta.offerId}`
                  }
                >
                  {props.lang === "RO" ? "Află mai mult" : "Find out more"}
                </Link>

                <Link
                  className="button-oferta w-button"
                  to={
                    props.lang === "RO"
                      ? `/oferte/rezervari/${props.oferta.offerId}`
                      : `/en/offers/reservations/${props.oferta.offerId}`
                  }
                >
                  {props.lang === "RO" ? "rezervă acum" : "book now"}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
