import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import ResRoomTop from "./components/ResRoomTop";

import data from "./data/ro.json";
import dataEN from "./data/en.json";

export default function ReservationRoom() {
  const currentRoom = useParams().room;
  const [lang, setLang] = useState("RO");
  const [roomData, setRoomData] = useState({});
  const [gdpr, setGdpr] = useState("");
  useEffect(() => {
    if (window.location.pathname.includes("/en/")) {
      setLang("EN");
      setGdpr(dataEN.gdpr)
      let currentRoomData = dataEN.camere.units.filter(
        room => room.roomId === currentRoom
      )[0];
      setRoomData(currentRoomData);
    } else {
      setLang("RO");
      setGdpr(data.gdpr)
      let currentRoomData = data.camere.units.filter(
        room => room.roomId === currentRoom
      )[0];
      setRoomData(currentRoomData);
    }
    window.scrollTo(0, 0);
  }, [currentRoom]);
  // console.log("roomData", roomData);
  return (
    <div className="body">
      <Navbar lang={lang} data={data} logo={data.logo} fb={data.fblink} />
      <ResRoomTop lang={lang} roomData={roomData} gdpr={gdpr} hotelEmail={data.contact.email[0]} hotelName={data.header.nume} />
      <Footer
        nume={data.header.nume}
        logo={data.logo}
        fb={data.fblink}
        ig={data.iglink}
        yt={data.ytlink}
      />
    </div>
  );
}
