import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Separator from "./components/Separator";
import RoomTop from "./components/RoomTop";
import CamereList from "./components/CamereList";

import data from "./data/ro.json";
import dataEN from "./data/en.json";

export default function Room() {
  const currentRoom = useParams().room;
  const [lang, setLang] = useState("RO");
  const [roomData, setRoomData] = useState({});
  useEffect(() => {
    if (window.location.pathname.includes("/en/")) {
      setLang("EN");
      let currentRoomData = dataEN.camere.units.filter(
        room => room.roomId === currentRoom
      )[0];
      setRoomData(currentRoomData);
    } else {
      setLang("RO");
      let currentRoomData = data.camere.units.filter(
        room => room.roomId === currentRoom
      )[0];
      setRoomData(currentRoomData);
    }
    window.scrollTo(0, 0);
  }, [currentRoom]);
  // console.log("roomData", roomData);
  return (
    <div className="body">
      <Navbar lang={lang} data={data} logo={data.logo} fb={data.fblink} />
      <RoomTop lang={lang} roomData={roomData} />
      <Separator />
      <div id="camere" className="section-standard">
        <div className="container-std">
          <h1 className="section-title">
            {lang === "RO" ? "Alte Camere" : "Other Rooms"}
          </h1>
          <p className="paragraph-standard">
            {lang === "RO" ? data.camere.text : dataEN.camere.text}
          </p>
          {lang === "RO" ? (
            <CamereList camere={data.camere} lang={lang} />
          ) : (
            <CamereList camere={dataEN.camere} lang={lang} />
          )}
        </div>
      </div>
      <Footer
        nume={data.header.nume}
        logo={data.logo}
        fb={data.fblink}
        ig={data.iglink}
        yt={data.ytlink}
      />
    </div>
  );
}
