import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import FoodItem from "./FoodItem";
import FoodSection from "./FoodSection";
import data from "../data/ro.json";
import dataEN from "../data/en.json";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    fontFamily: '"Open Sans"',
  },
}));

export default function FoodMenu(props) {
  const classes = useStyles();
  let lang = props.lang;
  console.log(lang);
  return (
    <div className={classes.root}>
      <FoodSection
        section="turtizza"
        lang={lang}
        sectionNameRo="TURTIZZA"
        sectionNameEn="TURTIZZA"
      />
      <FoodSection
        section="specialitati"
        lang={lang}
        sectionNameRo="SPECIALITĂȚI BIERHOF"
        sectionNameEn="BIERHOF SPECIALTIES"
      />
      <FoodSection
        section="post"
        lang={lang}
        sectionNameRo="BUNĂTĂȚI DE POST"
        sectionNameEn="LENT DELIGTHS"
      />
      <FoodSection
        section="ceaune"
        lang={lang}
        sectionNameRo="CEAUNE"
        sectionNameEn="HOT POTS"
      />
      <FoodSection
        section="selection"
        lang={lang}
        sectionNameRo="BIERHOF SELECTION"
        sectionNameEn="BIERHOF SELECTION"
      />
      <FoodSection
        section="burger"
        lang={lang}
        sectionNameRo="BURGER"
        sectionNameEn="BURGER"
      />
      <FoodSection
        section="desert"
        lang={lang}
        sectionNameRo="DESERT"
        sectionNameEn="DESSERT"
      />
      <FoodSection
        section="extras"
        lang={lang}
        sectionNameRo="EXTRAS"
        sectionNameEn="EXTRAS"
      />
      <FoodSection
        section="bere"
        lang={lang}
        sectionNameRo="BERE"
        sectionNameEn="BEER"
      />
      <FoodSection
        section="cafea"
        lang={lang}
        sectionNameRo="CAFEA"
        sectionNameEn="COFFEE"
      />
      <FoodSection
        section="ceai"
        lang={lang}
        sectionNameRo="CEAI"
        sectionNameEn="TEA"
      />
      <FoodSection
        section="limonada"
        lang={lang}
        sectionNameRo="LIMONADA"
        sectionNameEn="LEMONADE"
      />
      <FoodSection
        section="racoritoare"
        lang={lang}
        sectionNameRo="BĂUTURI RĂCORITOARE"
        sectionNameEn="SOFT DRINKS"
      />
      <FoodSection
        section="energizant"
        lang={lang}
        sectionNameRo="BĂUTURI ENERGIZANTE"
        sectionNameEn="ENERGY DRINKS"
      />
      <FoodSection
        section="cidru"
        lang={lang}
        sectionNameRo="CIDRU"
        sectionNameEn="CIDER"
      />
      <FoodSection
        section="strong"
        lang={lang}
        sectionNameRo="STRONG DRINKS"
        sectionNameEn="STRONG DRINKS"
      />
      <FoodSection
        section="vin"
        lang={lang}
        sectionNameRo="VIN"
        sectionNameEn="WINE"
      />
    </div>
  );
}
