import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import FacilitatiGrid from "./FacilitatiGrid";
import SliderSmall from "./SliderSmall";

export default function RoomTop(props) {
  const [roomData, setRoomData] = useState({});
  useEffect(() => {
    setRoomData(props.roomData);
  }, [props]);
  // console.log(roomData);
  return (
    <div className="section-standard">
      <div className="container-std first">
        <h1 id="testelement" className="room-title">
          {props.roomData.nume}
        </h1>
        <div className="top-button-holder room">
          {roomData.pret &&
            roomData.pret.map((el) => (
              <div className="pers-holder no-space w-embed">
                {Array(el.capacitate)
                  .fill()
                  .map((el) => (
                    <img
                      key={Math.random()}
                      src="/images/person.svg"
                      style={{ height: 20, marginRight: 4 }}
                      alt=""
                    />
                  ))}
                <strong style={{ marginLeft: 5 }}>
                  {el.pret} / {props.lang === "RO" ? "noapte" : "night"}
                </strong>
              </div>
            ))}

          <Link
            className="button-inline w-button"
            to={
              props.lang === "EN"
                ? `/en/reservations/${roomData.roomId}`
                : `/rezervari/${roomData.roomId}`
            }
          >
            {props.lang === "RO" ? "Rezervare nouă" : "New reservation"}
          </Link>
        </div>
        <p className="paragraph-standard">{props.roomData.descriere}</p>

        {roomData && roomData.foto && <SliderSmall images={roomData.foto} />}

        <p className="paragraph-standard">
          Următoarele facilități sunte disponibile în acest tip de cameră:
        </p>

        {roomData && roomData.facilitati && (
          <FacilitatiGrid fac={roomData.facilitati} />
        )}
      </div>
    </div>
  );
}
