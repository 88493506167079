import React from "react";
import Prev from "./left-white.svg";

export default function LeftArrow(props) {
  return (
    <div>
      <img
        src={Prev}
        onClick={props.onClick}
        className="slick-prev"
        alt="right arrow"
      />
    </div>
  );
}
