import React from "react";

export default function FacilitatiGrid(props) {
  // console.log(props);
  return (
    <div className="w-layout-grid facilitati-grid">
      {props.fac.map(item => (
        <div key={Math.random()} className="icon-text-holder">
          <img src={item.img} alt="" className="icon-facilitati" />
          <p className="facilitati-name">{item.nume}</p>
        </div>
      ))}
    </div>
  );
}
