import React from "react";
import { useState, useEffect } from "react";

import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import OfertaModule from "./components/OfertaModule";
import Despre from "./components/Despre";
import SliderMain from "./components/SliderMain";
import FoodMenu from "./components/FoodMenu";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Separator from "./components/Separator";

import "./css/normalize.css";
import "./css/webflow.css";
import "./css/addhotel.webflow.css";
import "./css/my.css";
import "./css/simple_navbar.css";

import data from "./data/ro.json";
import dataEN from "./data/en.json";

function App(props) {
  const [lang, setLang] = useState(props.lang ? props.lang : "RO");
  useEffect(() => {
    if (window.location.pathname.includes("/en/")) {
      setLang("EN");
    } else {
      setLang("RO");
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="App the-spacer">
      <Navbar lang={lang} data={data} logo={data.logo} fb={data.fblink} />
      <div id="home">
        <Hero data={data} dataEN={dataEN} lang={lang} />
      </div>

      {lang === "RO"
        ? data.oferte.map((item) => <OfertaModule lang={lang} oferta={item} />)
        : dataEN.oferte.map((item) => (
            <OfertaModule lang={lang} oferta={item} />
          ))}
      <Separator sepId="despre" />

      <Despre lang={lang} data={data} dataEN={dataEN} />

      {lang === "RO" ? (
        <SliderMain lang={lang} images={data.sliderMain} />
      ) : (
        <SliderMain lang={lang} images={dataEN.sliderMain} />
      )}

      <Separator sepId="meniu" />

      {/* 
      <div
        id="meniu"
        style={{
          maxWidth: window.innerWidth < 500 ? "80%" : "800px",
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: window.innerWidth < 500 ? 50 : 100,
          // marginBottom: 20,
          height: window.innerWidth < 500 ? 20 : 50,
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        }}
        // className="separator---horizontal---nomargin-up"
      ></div> */}

      {/* <div className="section-standard">
        <div className="container-std">
          <h1 className="section-title">{lang === "RO" ? "Meniu" : "Menu"}</h1>
          <p className="paragraph-standard">
            {lang === "RO" ? data.camere.text : dataEN.camere.text}
          </p>
          {lang === "RO" ? <FoodMenu lang={lang} /> : <FoodMenu lang={lang} />}
        </div>
      </div> */}

      {/* <Separator sepId="contact" /> */}

      {/* <div
        id="contact"
        style={{
          maxWidth: window.innerWidth < 500 ? "80%" : "800px",
          marginRight: "auto",
          marginLeft: "auto",
          marginTop: window.innerWidth < 500 ? 60 : 100,
          marginBottom: 20,
          borderTop: "1px solid rgba(0, 0, 0, 0.1)",
        }}
        // className="separator---horizontal---nomargin-up"
      ></div> */}

      <Contact
        lang={lang}
        contactRO={data.contact}
        contactEN={dataEN.contact}
      />

      <Footer
        nume={data.header.nume}
        logo={data.logo}
        fb={data.fblink}
        ig={data.iglink}
        yt={data.ytlink}
      />
    </div>
  );
}

export default App;
