import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Separator from "./components/Separator";
import CamereList from "./components/CamereList";
import { useHistory } from "react-router-dom";
import data from "./data/ro.json";
import dataEN from "./data/en.json";

export default function Politici() {
  let history = useHistory();
  const [lang, setLang] = useState("RO");
  const [policies, setPolicies] = useState([]);
  useEffect(() => {
    if (window.location.pathname.includes("/en/")) {
      setLang("EN");
      setPolicies(dataEN.politici);
    } else {
      setLang("RO");
      setPolicies(data.politici);
    }
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="body">
      <Navbar lang={lang} data={data} logo={data.logo} fb={data.fblink} />
      <div className="section-standard">
        <div
          className="container-std first"
          style={{
            display: "flex",
            flexWrap: "wrap",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <p
              className="paragraph-standard"
              style={{ textAlign: "center", width: "100%" }}
            >
              <strong>{lang === "RO" ? "Vă mulțumim! " : "Thank you! "}</strong>
              {lang === "RO"
                ? "Cererea dvs. de rezervare a fost trimisă cu succes. Vă vom contacta în cel mai scurt timp."
                : "Your reservation request was sent successfully. We will contact you as soon as possible."}
            </p>

            <div
              className="button-inline w-button"
              style={{ marginTop: 20, padding: 15, marginRight: 0 }}
              onClick={() => history.push(lang === "RO" ? "/" : "/en")}
            >
              {lang === "RO"
                ? "Înapoi la pagina principală"
                : "Back to home page"}
            </div>
          </div>
        </div>
      </div>

      <Footer
        nume={data.header.nume}
        logo={data.logo}
        fb={data.fblink}
        ig={data.iglink}
        yt={data.ytlink}
      />
    </div>
  );
}
