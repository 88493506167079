import React from "react";
import Next from "./right-white.svg";

export default function RightArrow(props) {
  return (
    <div>
      <img
        src={Next}
        onClick={props.onClick}
        className="slick-next"
        alt="right arrow"
      />
    </div>
  );
}
