import React from "react";
import FacilitatiGrid from "./FacilitatiGrid";

export default function Despre(props) {
  return (
    <div className="section-standard">
      <div className="container-std">
        <h1 className="section-title">
          {props.lang === "RO"
            ? `Despre ${props.data.header.nume}`
            : `About ${props.dataEN.header.nume}`}
        </h1>
        <p className="paragraph-standard">
          {props.lang === "RO" ? props.data.despre.p1 : props.dataEN.despre.p1}
        </p>
        <p className="paragraph-standard">
          {props.lang === "RO" ? props.data.despre.p2 : props.dataEN.despre.p2}
        </p>
        {props.lang === "RO" ? (
          <FacilitatiGrid lang={props.lang} fac={props.data.facilitati} />
        ) : (
          <FacilitatiGrid lang={props.lang} fac={props.dataEN.facilitati} />
        )}

        <p className="paragraph-standard">
          {props.lang === "RO" ? props.data.despre.p3 : props.dataEN.despre.p3}
        </p>
      </div>
    </div>
  );
}
