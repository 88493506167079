import React from "react";

export default function Separator(props) {
  return (
    <div
      id={props.sepId}
      className="separator---horizontal---nomargin-up"
    ></div>
  );
}
