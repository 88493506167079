import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import OfferTop from "./components/OfferTop";

import data from "./data/ro.json";
import dataEN from "./data/en.json";

export default function Offer() {
  const currentOffer = useParams().offer;
  const [lang, setLang] = useState("RO");
  const [offerData, setOfferData] = useState({});
  useEffect(() => {
    if (window.location.pathname.includes("/en/")) {
      setLang("EN");
      let currentOfferData = dataEN.oferte.filter(
        offer => offer.offerId === currentOffer
      )[0];
      setOfferData(currentOfferData);
    } else {
      setLang("RO");
      let currentOfferData = data.oferte.filter(
        offer => offer.offerId === currentOffer
      )[0];
      setOfferData(currentOfferData);
    }
    window.scrollTo(0, 0);
  }, [currentOffer]);
  console.log("offerData", offerData);
  return (
    <div className="body">
      <Navbar lang={lang} data={data} logo={data.logo} fb={data.fblink} />
      <OfferTop lang={lang} offerData={offerData} />
      <Footer
        nume={data.header.nume}
        logo={data.logo}
        fb={data.fblink}
        ig={data.iglink}
        yt={data.ytlink}
      />
    </div>
  );
}
