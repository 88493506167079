import React from "react";
import { useState } from "react";

import { NavHashLink as NavLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

export default function Navbar(props) {
  const [langSelector, setLangSelector] = useState(false);
  const classToggle = () => {
    const navs = document.querySelectorAll(".Navbar__Items");
    navs.forEach((nav) => nav.classList.toggle("Navbar__ToggleShow"));
  };
  return (
    <div className="Navbar" id="navbarmain">
      <div className="iconSpace">
        <div className="Navbar__Link Navbar__Link-brand">
          <NavLink to={props.lang === "EN" ? "/en/#home" : "/#home"}>
            <img src={props.logo} alt="" className="hotel-logo" />
          </NavLink>
        </div>

        <div
          className="language-selector w-dropdown"
          onClick={() => setLangSelector(!langSelector)}
          onMouseOver={() => setLangSelector(true)}
          onMouseOut={() => setLangSelector(false)}
        >
          <div className="dropdown-toggle w-dropdown-toggle">
            <div className="language-arrow w-icon-dropdown-toggle"></div>
            <img
              src="/images/globe-white_1globe-white.png"
              width="60"
              alt=""
              className="image-2"
            />
          </div>

          <nav
            className={`language-choices w-dropdown-list ${
              langSelector && "w--open"
            }`}
          >
            <Link className="dropdown-link w-dropdown-link" to="/en/">
              EN
            </Link>
            <Link className="dropdown-link w-dropdown-link" to="/">
              RO
            </Link>
          </nav>
        </div>
        {props.fb !== null && (
          <a
            href={props.fb}
            target="_blank"
            rel="noopener noreferrer"
            className="Navbar__Link social-icon"
          >
            <img
              src="/images/fb-icon-round_1fb-icon-round.png"
              alt=""
              className="face-icon"
            />
          </a>
        )}
      </div>

      <div onClick={classToggle} className="Navbar__Link Navbar__Link-toggle">
        <img
          src="/images/menu.svg"
          alt=""
          style={{ height: 14, marginBottom: 4 }}
        />
      </div>

      <nav className="Navbar__Items Navbar__Items--right">
        <NavLink to={props.lang === "EN" ? "/en#home" : "/#home"}>
          <div onClick={classToggle} className="Navbar__Link nav-button">
            Home
          </div>
        </NavLink>

        <NavLink to={props.lang === "EN" ? "/en#despre" : "/#despre"}>
          <div onClick={classToggle} className="Navbar__Link nav-button">
            {props.lang === "EN" ? "About us" : "Despre noi"}
          </div>
        </NavLink>

        <a href="https://foodaway.ro/bierhof" target="_blank">
          <div onClick={classToggle} className="Navbar__Link nav-button">
            {props.lang === "EN" ? "order online" : "comandă online"}
          </div>
        </a>

        <NavLink to={props.lang === "EN" ? "/en#contact" : "/#contact"}>
          <div onClick={classToggle} className="Navbar__Link nav-button">
            Contact
          </div>
        </NavLink>
      </nav>
    </div>
  );
}
