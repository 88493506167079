import React from "react";
import { Link } from "react-router-dom";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

export default function Camere(props) {
  return (
    <div className="card-holder">
      {props.camere.units.map((item) => (
        <Link
          key={Math.random()}
          className="card w-inline-block"
          to={
            props.lang === "RO"
              ? `/camere/${item.roomId}`
              : `/en/rooms/${item.roomId}`
          }
        >
          <div
            className="card-image"
            style={{ backgroundImage: `url(${item.foto[0]})` }}
          ></div>
          <div className="card-content-holder">
            <p className="card-title">{item.nume}</p>
            {item.pret.map((el) => (
              <div className="stars-holder w-embed">
                {Array(el.capacitate)
                  .fill()
                  .map((el) => (
                    <img
                      key={Math.random()}
                      src="/images/person.svg"
                      style={{ height: 16 }}
                      alt=""
                    />
                  ))}
                <p className="in-card-p">
                  {el.pret} / {props.lang === "RO" ? "noapte" : "night"}
                </p>
              </div>
            ))}

            <p className="paragraph-standard">{item.descriere}</p>
            <p className="button-standard-100">mai mult</p>
          </div>
        </Link>
      ))}

      <div className="swiper-end"></div>
    </div>
  );
}
