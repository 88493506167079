import React from "react";

export default function Contact(props) {
  return (
    <div className="section-standard">
      <div className="container-std">
        <h1 className="section-title">Contact</h1>
        <p className="paragraph-standard">
          {props.lang === "RO" ? props.contactRO.text : props.contactEN.text}
        </p>
        <div className="contact">
          <div className="contact-element">
            {props.lang === "RO" ? (
              <p className="paragraph-standard">
                <strong>Adresa:</strong> {props.contactRO.adresa}
              </p>
            ) : (
              <p className="paragraph-standard">
                <strong>Address:</strong> {props.contactEN.adresa}
              </p>
            )}

            <a
              href={props.contactRO.GPS}
              target="_blank"
              rel="noopener noreferrer"
              className="button-navigare w-button"
            >
              {props.lang === "RO" ? "Navigare gps" : "GPS Navigation"}
            </a>
          </div>
          <div className="contact-element">
            {props.contactRO.email.map((item) => (
              <p className="paragraph-standard">
                <strong>Email:</strong> <a href={`mailto:${item}`}>{item}</a>
              </p>
            ))}
            {props.contactRO.phone.map((item) => (
              <p className="paragraph-standard">
                <strong>{props.lang === "RO" ? "Telefon: " : "Phone: "}</strong>
                <a href={`tel:${item}`}>{item}</a>
              </p>
            ))}
            {props.contactRO.fax.map((item) => (
              <p className="paragraph-standard">
                <strong>Fax: </strong>
                {item}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
